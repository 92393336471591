import React from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';

import logo from './logo.svg';
import '@aws-amplify/ui-react/styles.css';
import './App.css';

import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <div>
        <h1>Jason's Land</h1>
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <Authenticator>
        {({ signOut, user }) => (
          <div className="App">
            <p>
              Hey {user.username} welcome to my channel, with Authentication!
            </p>
            <button onClick={signOut}>Sign out</button>
          </div>
        )}
      </Authenticator>
      </header>
      <footer className="App-footer">
        <p>jasons.land 2023</p>
      </footer>
    </div>
  );
}

export default App;